





.StyledResultItemInner_s1wab2yy{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;margin:0;padding:4px 0;}.StyledResultItemInner_s1wab2yy > .cssIcon_cc7xf1o{color:#a9a9a9;position:absolute;left:2px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.StyledResultItem_s1gbv2au{width:100%;margin:0;padding:0 0 0 24px;background:transparent;color:#3b3b3b;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;border-radius:4px;}.StyledResultItem_s1gbv2au .TypographyBase_t102nrcx{margin:0;cursor:pointer;color:inherit;-webkit-flex:1;-ms-flex:1;flex:1;}.StyledResultItem_s1gbv2au > .ButtonBox_b1h9lfi4{width:auto;min-width:initial;height:auto;padding:0;background-color:transparent;z-index:4;position:relative;}.StyledResultItem_s1gbv2au > .ButtonBox_b1h9lfi4 .cssIcon_cc7xf1o{fill:#a9a9a9;}.StyledResultItem_s1gbv2au > .ButtonBox_b1h9lfi4:hover .cssIcon_cc7xf1o,.StyledResultItem_s1gbv2au > .ButtonBox_b1h9lfi4:active .cssIcon_cc7xf1o{fill:#3b3b3b;}.StyledResultItem_s1gbv2au:hover,.StyledResultItem_s1gbv2au:active{background:#F5F5F5;color:#000000;}
.StyledResultList_s12lsljd{width:100%;list-style:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0;margin:0;}
.StyledResultTitle_ss470st.Heading5_h1m9r6p9{padding:0;margin-bottom:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.StyledResultTitle_ss470st.Heading5_h1m9r6p9 .Paragraph12_p12g4ckv{color:#7A7A7A;cursor:pointer;}.StyledResultTitle_ss470st.Heading5_h1m9r6p9 .Paragraph12_p12g4ckv:hover,.StyledResultTitle_ss470st.Heading5_h1m9r6p9 .Paragraph12_p12g4ckv:active{color:#BC39E5;}
.StyledResult_smb5p81{width:100%;margin:0 0 8px 0;}
.StyledResultListArea_s1lk25fm{grid-area:list;}
.StyledAutoCompleteOverlay_s1vtethi{position:fixed;top:0;left:0;width:100%;height:100vh;background:rgba(255,255,255,0.5);z-index:8;opacity:0;visibility:hidden;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;}.StyledAutoCompleteOverlay_s1vtethi.cssIsActive_cj0m6m1{opacity:1;visibility:visible;}
.StyledAutoComplete_spgmdox.Panel_pmrsgiv{position:absolute;top:100%;left:0;width:100%;border-radius:4px;padding:10px 15px;box-shadow:rgba(17,17,26,0.1) 0 4px 16px,rgba(17,17,26,0.1) 0 8px 24px,rgba(17,17,26,0.1) 0 16px 56px;-webkit-transition:all 0.1s cubic-bezier(0.22,0.61,0.36,1);transition:all 0.1s cubic-bezier(0.22,0.61,0.36,1);min-height:100px;max-height:calc(100vh - 150px);overflow:hidden auto;display:grid;grid-template-columns:4fr 8fr;grid-template-areas:"list products";gap:0 30px;opacity:0;visibility:hidden;z-index:-1;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv::-webkit-scrollbar{width:10px;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv::-webkit-scrollbar-track{background:#F5F5F5;border-radius:0;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv::-webkit-scrollbar-thumb{background:#DEDEDE;border-radius:0;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv.cssIsActive_cj0m6m1{opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);z-index:10;visibility:visible;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv .StyledResult_smb5p81.cssTaps_cxivmd6{grid-area:taps;width:auto;display:none;}@media (max-width:992px){.StyledAutoComplete_spgmdox.Panel_pmrsgiv .StyledResult_smb5p81.cssTaps_cxivmd6{display:block;}}.StyledAutoComplete_spgmdox.Panel_pmrsgiv .StyledResult_smb5p81.cssTaps_cxivmd6 .StyledTags_sfl1o1o{grid-template-rows:1fr 1fr;display:grid;-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;white-space:nowrap;grid-auto-flow:column;width:100%;overflow:auto;padding-bottom:8px;margin-bottom:0;}.StyledAutoComplete_spgmdox.Panel_pmrsgiv .StyledResult_smb5p81.cssProducts_c1s1e9gc{grid-area:products;}@media (max-width:992px){.StyledAutoComplete_spgmdox.Panel_pmrsgiv{box-shadow:none;border:none;border-radius:0;margin:0;}}@media (max-width:768px){.StyledAutoComplete_spgmdox.Panel_pmrsgiv{display:block;grid-template-areas:none;gap:initial;}}
